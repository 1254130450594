import { graphql } from "gatsby"
import React from "react"
import { Layout } from "@bw/layouts"
import { Seo, Button } from "@bw/bits"
import { PageHeader } from "@bw/modules"
import * as modules from "@bw/modules/resolvers"
import dateFormat from "utils/dateFormat"

const EventTemplate = ({ data, pageContext }) => {
  const { page } = data

  if (typeof page === "undefined") {
    return null
  }

  return (
    <Layout {...{ pageContext }}>
      <Seo
        title={page.metaTitle || page.title}
        description={page.metaDescription}
        meta={[
          {
            name: "robots",
            content: page.doNotIndex
              ? "noindex, nofollow"
              : "max-image-preview:large, index, follow",
          },
        ]}
      />
      {/*
       * Loading the Header module, ex Hero or PageHeader
       */}
      <PageHeader
        title={page.title}
        subtitle={dateFormat(page.date, pageContext.locale)}
      />
      {/*
       * Loading the Contents modules, ex Content or CallToAction, TeasersLatest
       */}
      {page.content
        .filter(module => module !== null)
        .map((module, i) => {
          const LoadedModule = modules[module.__typename.replace("Module", "")]
          if (typeof LoadedModule === "undefined") {
            return null
          }

          if (module.buttonType?.value === "url") {
            module.button = (
              <Button
                label={module.buttonText}
                href={module.buttonUrl}
                external={module.buttonIsExternal}
              />
            )
          }

          if (module.buttonType?.value === "entry") {
            module.button = (
              <Button label={module.buttonText} to={module.buttonEntry.url} />
            )
          }

          return <LoadedModule key={i} {...module} />
        })}
    </Layout>
  )
}

export default EventTemplate

export const eventQuery = graphql`
  query eventQuery($id: String!) {
    page: collectionEvents(id: { eq: $id }) {
      title
      date
      metaTitle
      metaDescription
      image {
        url
      }
      doNotIndex

      teaserImage {
        url
      }
      content {
        __typename
        suptitle
        title
        subtitle
        backgroundVariant
        buttonType {
          value
        }
        buttonUrl
        buttonIsExternal
        buttonText
        buttonPosition {
          value
        }
        buttonEntry {
          url
        }

        ... on CallToActionModule {
          ...CallToActionModuleFragment
        }
        ... on ContentModule {
          ...ContentModuleFragment
        }
        ... on ContentWithImageModule {
          ...ContentWithImageModuleFragment
        }
        ... on GalleryModule {
          ...GalleryModuleFragment
        }
        ... on ContentOnColumnsModule {
          ...ContentOnColumnsModuleFragment
        }
        ... on DownloadsModule {
          ...DownloadsModuleFragment
        }
        ... on TeasersLatestModule {
          ...TeasersLatestModuleFragment
        }
        ... on TeasersCustomModule {
          ...TeasersCustomModuleFragment
        }
        ... on TeasersManualModule {
          ...TeasersManualModuleFragment
        }
        ... on LogoGridModule {
          ...LogoGridModuleFragment
        }
        ... on ImageModule {
          ...ImageModuleFragment
        }
        ... on ImagesLinksModule {
          ...ImagesLinksModuleFragment
        }
        ... on VideoModule {
          ...VideoModuleFragment
        }
        ... on FormModule {
          ...FormModuleFragment
        }
        ... on TimelineModule {
          ...TimelineModuleFragment
        }
      }
    }
  }
`
